import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll() {
		const params = {
			order: 'desc',
			order_by: 'name',
			offset: 0,
			limit: 0,
		}
		const { data } = await useJwt.get(API_ROUTES.dependency.get, params)

		return data
	},
}
